<template>
  <div>
    <h2
      v-if="!noTitle"
      class="is-size-3"
    >
      School en opleiding
    </h2>

    <template v-if="!readOnly">
      <!--Standaardveld voor scholen-->
      <template v-if="!data.SchoolHandmatig">
        <ValidatedFormField
          :validator="$v.data.School_ID"
          label="School*"
        >
          <Multiselect
            v-model="schoolKeuze"
            label="Naam"
            track-by="School_ID"
            placeholder="Naam school"
            select-label="Kies school"
            selected-label="Gekozen"
            deselect-label="Verwijder"
            :options="beschikbareScholen"
            @input="schoolNaamInput"
          >
            <template #noOptions>
              Scholen worden geladen...
            </template>
            <template #noResult>
              Geen scholen gevonden met deze naam.
            </template>
          </Multiselect>
        </ValidatedFormField>
        <ValidatedFormField>
          <Multiselect
            v-model="plaatsKeuze"
            placeholder="Plaats school"
            select-label="Kies plaats"
            selected-label="Gekozen"
            deselect-label="Verwijder"
            :options="beschikbarePlaatsen"
            :disabled="beschikbarePlaatsen.length < 2"
            @input="schoolPlaatsInput"
          >
            <template #noOptions>
              Scholen worden geladen...
            </template>
            <template #noResult>
              Geen plaatsen gevonden met deze naam.
            </template>
          </Multiselect>
        </ValidatedFormField>

        <BCheckbox
          v-model="schoolToggle"
          type="is-info"
        >
          Mijn school staat niet in de lijst
        </BCheckbox>
        <BMessage
          title="Tip"
          :active.sync="schoolToggle"
          type="is-warning"
          has-icon
          closable
        >
          Vul eerst de plaats van je school in, dan kun je je school makkelijker vinden.
          Klik <a
            href="#"
            class="link"
            @click.prevent="setSchoolHandmatig"
          >hier</a> om handmatig je school in te voeren.
        </BMessage>
      </template>

      <!--Handmatig veld voor scholen-->
      <template v-else>
        <SslTextField
          v-model="data.School"
          label="Naam school*"
          :validator="$v.data.School"
          @input="() => $v.data.School.$touch()"
        />
        <SslTextField
          v-model="data.SchoolPlaats"
          label="Plaats school*"
          :validator="$v.data.SchoolPlaats"
          @input="() => $v.data.SchoolPlaats.$touch()"
        />
      </template>

      <SslRadio
        v-model="data.Niveau"
        label="Niveau*"
        :options="niveauOptions"
        :validator="$v.data.Niveau"
        @input="() => $v.data.Niveau.$touch()"
      />

      <SslRadio
        v-model="data.Profiel"
        label="Profiel*"
        :options="profielOptions"
        :validator="$v.data.Profiel"
        @input="() => $v.data.Profiel.$touch()"
      />
    </template>
    <template v-else>
      <table class="table">
        <tr><th>School</th><td>{{ data.School }} in {{ data.SchoolPlaats }}</td></tr>
        <tr><th>Niveau</th><td>{{ niveauOptions[data.Niveau] }}</td></tr>
        <tr><th>Profiel</th><td>{{ profielOptions[data.Profiel] }}</td></tr>
      </table>
    </template>
  </div>
</template>

<script>
  import {BCheckbox} from 'buefy/src/components/checkbox';
  import {BMessage} from 'buefy/src/components/message';
  import {computed, ref, watch} from 'vue';
  import Multiselect from 'vue-multiselect';
  import {maxLength, required, requiredIf} from 'vuelidate/lib/validators';

  import SslRadio from '../elements/SslRadio.vue';
  import SslTextField from '../elements/SslTextField.vue';
  import ValidatedFormField from '../elements/ValidatedFormField.vue';

  import useGetRequest from '@/js/vue/util/getRequest';

  const niveauOptions = {havo: 'havo', vwo: 'vwo'};
  const profielOptions = {CM: 'C&M', EM: 'E&M', NT: 'N&T', NG: 'N&G'};

  const scholenEndpoint = '/api/overzicht/scholen';

  export default {
    name: 'PartialFormSchool',
    components: {
      BCheckbox,
      BMessage,
      Multiselect,
      SslRadio,
      SslTextField,
      ValidatedFormField,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      schoolOpties: {
        type: Array,
        default: null,
      },
      noTitle: {
        type: Boolean,
      },
    },
    setup(props) {
      const loadApiScholen = ref(false);

      const {result: scholenEndpointResult} = useGetRequest(scholenEndpoint, {scholen: []}, loadApiScholen);
      const scholen = computed(() => props.schoolOpties ?? scholenEndpointResult.value.scholen);

      const laadScholen = () => loadApiScholen.value = loadApiScholen.value || (scholen.value.length === 0);

      const schoolToggle = ref(false);

      const plaatsKeuze = ref(null);
      // Filter scholen op plaats, als we wel een plaats maar nog geen school hebben gekozen
      const plaatsFilter = computed(() => !props.data.School_ID ? plaatsKeuze.value : null);

      const schoolKeuze = ref(null);
      const schoolFilter = computed(() => schoolKeuze.value?.Naam ?? null);
      watch(
        schoolKeuze,
        (newVal) => {
          props.data.School = newVal?.Naam;
          props.data.SchoolPlaats = newVal?.Plaats;
        },
      );

      const beschikbareScholen = computed(() => scholen.value.filter((school) =>
        plaatsFilter.value === null || school.Plaats === plaatsFilter.value));
      const beschikbarePlaatsen = computed(() => {
        const plaatsenMetSchool = scholen.value.filter((school) =>
          schoolFilter.value === null || school.Naam === schoolFilter.value);

        // We gebruiken een Set om unieke plaatsnamen over te houden
        return Array.from(new Set(plaatsenMetSchool.map((school) => school.Plaats))).sort();
      });

      watch(
        () => props.readOnly,
        // Als we gaan bewerken, laad dan de scholen in
        (newVal, oldVal) => oldVal && !newVal ? laadScholen() : undefined,
      );

      return {
        niveauOptions,
        profielOptions,
        scholen,
        schoolToggle,
        plaatsKeuze,
        schoolKeuze,
        beschikbareScholen,
        beschikbarePlaatsen,
      };
    },
    watch: {
      scholen: {
        immediate: true,
        handler(newVal) {
          if (newVal.length > 0) {
            // Na het ophalen van de scholen 'resetten' we de School_ID om de dropdowns juist in te stellen
            this.setSchoolID(this.data.School_ID, false);
          }
        },
      },
    },
    validations() {
      return {
        data: {
          School_ID: {
            required: requiredIf(() => !this.data.SchoolHandmatig),
          },
          School: {
            required: requiredIf(() => this.data.SchoolHandmatig),
            maxLength: maxLength(100),
          },
          SchoolPlaats: {
            required: requiredIf(() => this.data.SchoolHandmatig),
            maxLength: maxLength(100),
          },
          Niveau: {
            required,
          },
          Profiel: {
            required,
          },
        },
      };
    },
    methods: {
      schoolNaamInput(value) {
        const mogelijkheden = this.scholen.filter((school) => (school.Naam === value?.Naam));

        if (mogelijkheden.length === 1) {
          this.setSchool(mogelijkheden[0]);
        } else {
          this.plaatsKeuze = null;
          this.setSchoolID(null);
        }
      },
      schoolPlaatsInput(value) {
        const mogelijkheden = this.scholen.filter((school) =>
          (school.Plaats === value && (this.schoolKeuze === null || school.Naam === this.schoolKeuze.Naam)));

        if (mogelijkheden.length === 1) {
          this.setSchool(mogelijkheden[0]);
        } else {
          this.setSchoolID(null);
        }
      },
      setSchool(value) {
        this.data.School_ID = value?.School_ID;
        this.schoolKeuze = value ?? null;
        this.plaatsKeuze = value?.Plaats ?? null;
        this.$v.data.School_ID.$touch();
      },
      setSchoolID(value, touch = true) {
        this.data.School_ID = value;
        if (value) {
          const school = this.scholen.find((school) => school.School_ID === this.data.School_ID);
          this.setSchool(school);
        }

        if (touch) {
          this.$v.data.School_ID.$touch();
        }
      },
      setSchoolHandmatig() {
        this.data.SchoolHandmatig = true;
        this.schoolToggle = false;
        this.setSchool(null);
      },
    },
  };
</script>
