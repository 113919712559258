<template>
  <div class="resultaten-form">
    <h1 class="title is-size-1">
      Jouw examenuitslag
    </h1>
    <div class="content">
      <p v-if="actief">
        Met dit formulier willen we je vragen om je examenresultaten aan ons door te geven.<br />
        Alvast hartelijk bedankt voor je medewerking!
      </p>
      <p v-else>
        Hieronder staan de gegevens die je hebt ingevuld. Hartelijk dank voor het invullen!
      </p>
    </div>
    <h2 class="is-size-2">
      Gehele examen
    </h2>
    <div class="content">
      <SslRadio
        v-model="formData.ExamenResultaat"
        label="Wat was het uiteindelijke resultaat van je gehele examen?"
        :options="uitslagOpties"
        :disabled="examenResultaatDisabled"
        :validator="$v.formData.ExamenResultaat"
        @input="() => $v.formData.ExamenResultaat.$touch()"
      />
    </div>
    <h2 class="is-size-2">
      Cijfers vakken
    </h2>
    <div class="content">
      <p>
        Hieronder staan de vakken vermeld waarvan je bij ons een cursus gevolgd hebt.
        Zou je zo vriendelijk willen zijn voor elk vak je cijfers in te vullen?
      </p>
      <div v-for="(vak, cfkey) in formData.vakResultaten" :key="'cf' + cfkey">
        <h4 class="is-size-4">
          {{ vak.vak.naam }}
        </h4>
        <div class="columns cijfer-columns">
          <div v-for="(cijfer, type) in cijferKolommen" :key="type" class="column cijfers">
            <SslCijferField
              v-model="vak[type]"
              :label="cijfer.label"
              :disabled="!actief || savedData.vakResultaten[cfkey][type] !== ''"
              :validator="$v.formData.vakResultaten.$each.$iter[cfkey][type]"
              vertical
              @blur="() => $v.formData.vakResultaten.$each[cfkey][type].$touch()"
            />
          </div>
        </div>
      </div>
    </div>
    <h2 class="is-size-2">
      Opmerkingen voor docenten
    </h2>
    <div class="content">
      <p>
        Onze docenten vinden het leuk om van je te horen.
        Je kunt hieronder voor elk vak dat je gevolgd hebt je eventuele opmerkingen kwijt.
        Ze worden dan doorgestuurd naar je docenten.
      </p>
      <div v-for="(vak, opmkey) in formData.vakResultaten" :key="'opm' + opmkey">
        <h4 class="is-size-4">
          {{ vak.vak.naam }}
        </h4>
        <p v-if="docenten[vak.Vak_ID]">
          Docenten: {{ docenten[vak.Vak_ID] }}
        </p>
        <SslTextField
          v-model="vak.Opmerkingen"
          type="textarea"
          placeholder="Ruimte voor feedback en/of opmerkingen bij dit vak"
          :disabled="!actief || savedData.vakResultaten[opmkey].Opmerkingen !== null"
          :validator="$v.formData.vakResultaten.$each.$iter[opmkey].Opmerkingen"
          @input="() => $v.formData.vakResultaten.$each[opmkey].Opmerkingen.$touch()"
        />
      </div>
    </div>
    <h2 class="is-size-2">
      Opmerkingen voor de organisatie
    </h2>
    <div class="content">
      <p>
        Mocht je nog algemene opmerkingen hebben, dan kun je die hieronder invullen.
        We lezen alle inzendingen en proberen ook te reageren op alle vragen en feedback.
      </p>
      <SslTextField
        v-model="formData.Opmerkingen"
        type="textarea"
        placeholder="Ruimte voor algemene feedback en/of opmerkingen"
        :disabled="!actief || savedData.Opmerkingen !== null"
        :validator="$v.formData.Opmerkingen"
      />
    </div>
    <BButton
      type="is-success"
      icon-left="send"
      :loading="isSaving"
      :disabled="sendDisabled"
      @click="saveClick"
    >
      Versturen
    </BButton>
  </div>
</template>

<script>
  import {BButton} from 'buefy/src/components/button';
  import {ref} from 'vue';
  import {between, maxLength, required} from 'vuelidate/lib/validators';

  import SslCijferField from '../elements/SslCijferField.vue';
  import SslRadio from '../elements/SslRadio.vue';
  import SslTextField from '../elements/SslTextField.vue';

  import {eenDecimaal} from '../validation/index';

  import usePostRequest from '@/js/vue/util/postRequest';

  export default {
    name: 'ResultatenForm',
    components: {
      BButton,
      SslCijferField,
      SslRadio,
      SslTextField,
    },
    props: {
      resultaten: {
        type: Object,
        required: true,
      },
      docenten: {
        type: Object,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const postEndpoint = window.location.href;
      const handlePostResponse = ref();
      const {
        isSaving,
        postRequest,
        postRequestErrorTitle,
      } = usePostRequest(postEndpoint, handlePostResponse);
      postRequestErrorTitle.value = 'Fout opgetreden bij het verwerken van je resultaten';

      return {
        isSaving,
        handlePostResponse,
        postRequest,
      };
    },
    data() {
      return {
        formData: {},
        savedData: {},
        key: false,
        isValid: true,
      };
    },
    computed: {
      actief() {
        return this.formData.Actief;
      },
      examenResultaatDisabled() {
        // 'definitieve' resultaten kunnen niet meer worden aangepast
        const definitief = ['Geslaagd', 'Gezakt'];
        return !this.actief || definitief.includes(this.savedData.ExamenResultaat);
      },
      uitslagOpties() {
        const res = {
          Geslaagd: 'Ik ben geslaagd',
          Gezakt: 'Ik ben gezakt',
        };
        if (this.config.heeftHerexamenOptie) {
          res.Herexamen = 'Ik heb een herexamen';
        }
        return res;
      },
      cijferKolommen() {
        return Object.keys(this.config.cijfers)
          .filter((type) => this.config.cijfers[type].tonen)
          .reduce((res, type) => {
            res[type] = this.config.cijfers[type];
            return res;
          }, {});
      },
      vakkenValidator() {
        const standaard = {
          Opmerkingen: {
            maxLength: maxLength(65535),
          },
        };
        const cijfers = Object.keys(this.config.cijfers)
          .filter((type) => this.config.cijfers[type].tonen)
          .reduce((res, type) => {
            res[type] = {
              between: between(1, 10),
              eenDecimaal,
            };
            return res;
          }, {});
        return {
          ...standaard,
          ...cijfers,
        };
      },
      sendDisabled() {
        return !this.actief;
      },
    },
    watch: {
      resultaten: {
        handler: 'setFormData',
        immediate: true,
      },
    },
    mounted() {
      this.handlePostResponse = this.onPostRequestSucces; // deduplicate this on full setup migration
    },
    methods: {
      validateForm() {
        this.$v.formData.$touch();
        this.isValid = !this.$v.formData.$invalid;
      },
      confirmInvalid() {
        this.focusFirstInvalid();
      },
      focusFirstInvalid(component = this) {
        // based on https://markus.oberlehner.net/blog/vue-form-validation-with-vuelidate/
        if (component.validator && component.validator.$invalid) {
          component.$el.scrollIntoView();
          component.$el.focus();
          return true;
        }

        let focused = false;

        component.$children.some((childComponent) => {
          focused = this.focusFirstInvalid(childComponent);
          return focused;
        });

        return focused;
      },
      setFormData() {
        this.formData = structuredClone(this.resultaten);
        // Convert cijfers from null to empty string
        this.formData.vakResultaten.map((vak) => {
          for (const type of Object.keys(this.config.cijfers)) {
            if (vak[type] === null) {
              vak[type] = '';
            }
          }
          return vak;
        });
        this.setSavedData();
      },
      setSavedData() {
        this.savedData = structuredClone(this.formData);
      },
      saveClick() {
        this.validateForm();
        if (!this.isValid) {
          this.$buefy.dialog.alert({
            title: 'Ongeldige input',
            type: 'is-warning',
            hasIcon: true,
            message: 'Er is iets mis met de ingevoerde gegevens.',
            onConfirm: this.confirmInvalid,
          });
          return;
        }
        this.postRequest(this.formData);
      },
      onPostRequestSucces() {
        this.$buefy.dialog.alert({
          title: 'Uitslagen opgeslagen',
          type: 'is-success',
          hasIcon: true,
          message: 'Je reactie is opgeslagen, bedankt voor het invullen!',
        });
        this.setSavedData();
      },
    },
    validations() {
      return {
        formData: {
          ExamenResultaat: {
            required,
          },
          Opmerkingen: {
            maxLength: maxLength(65535),
          },
          vakResultaten: {
            $each: this.vakkenValidator,
          },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  h1.title {
    margin-top: 48px;
  }

  .columns.cijfer-columns {
    max-width: 33em;
  }

  [disabled].radio,
  [disabled].input,
  [disabled].textarea {
    color: inherit;
  }
</style>
