<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Cijfers aanpassen
      </p>
    </header>
    <section class="modal-card-body">
      <p>
        Geef je gemiddelde SE-cijfer tot nu toe op, afgerond op één decimaal.
      </p>
      <LazyLoadingWrapper
        :loading="data.vakken.length === 0"
        :has-error="loadingError"
      >
        <template #loadingDescription>
          <p>Cijfers worden geladen...</p>
        </template>
        <SslCijferField
          v-for="vak in data.vakken"
          :key="vak.Vak_ID"
          v-model="data.cijfers[vak.Vak_ID]"
          :track-by="vak.Vak_ID"
          :vak="vak"
          :validator="$v.data.cijfers.$each.$iter[vak.Vak_ID]"
          @input="() => $v.data.cijfers.$each[vak.Vak_ID].$touch()"
        />
      </LazyLoadingWrapper>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-right is-danger"
        type="button"
        @click="() => $parent.close()"
      >
        Annuleren
      </button>
      <button
        class="button is-right is-success"
        type="button"
        @click="opslaanClick"
      >
        Opslaan
      </button>
    </footer>
  </div>
</template>

<script>
  import {computed, ref, set as setReactive} from 'vue';
  import {between, required} from 'vuelidate/lib/validators';

  import SslCijferField from '../elements/SslCijferField.vue';

  import {eenDecimaal} from '../validation/index';

  import usePostRequest from '@/js/vue/util/postRequest';

  import LazyLoadingWrapper from '@/js/vue/wrappers/LazyLoadingWrapper.ts.vue';

  export default {
    name: 'SslCijferEditor',
    components: {
      LazyLoadingWrapper,
      SslCijferField,
    },
    props: {
      cursusreeks: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const postEndpoint = computed(() => `/api/wijzigen/cijfers/${props.cursusreeks.slug}`);
      const handlePostResponse = ref();
      const {
        isSaving,
        postRequest,
        postRequestErrorTitle,
      } = usePostRequest(postEndpoint, handlePostResponse);
      postRequestErrorTitle.value = 'Fout opgetreden bij het aanpassen van je cijfers';

      return {
        isSaving,
        handlePostResponse,
        postRequest,
      };
    },
    data() {
      return {
        data: {
          vakken: [],
          cijfers: {},
        },
        loadingError: false,
      };
    },
    computed: {
      formData() {
        return {
          cijfers: this.data.cijfers,
        };
      },
    },
    mounted() {
      this.handlePostResponse = this.onPostRequestSucces; // deduplicate this on full setup migration
      $http.get('/api/overzicht/' + this.cursusreeks.slug + '/cijfers')
        .catch(() => {
          this.loadingError = true;
        }).then((response) => {
          this.data.vakken = response.data.vakken;
          Object.keys(response.data.cijfers).forEach((vakID) => {
            setReactive(this.data.cijfers, vakID, response.data.cijfers[vakID]);
          });
          this.$v.$touch();
        });
    },
    methods: {
      checkIfFormValid() {
        this.$v.$touch();
        return !(this.$v.$invalid);
      },
      opslaanClick() {
        this.opslaan();
      },
      opslaan() {
        this.postRequest(this.formData);
      },
      onPostRequestSucces() {
        this.$emit('save');
        this.$parent.close();
      },
    },
    validations: {
      data: {
        cijfers: {
          $each: {
            required,
            between: between(1, 10),
            eenDecimaal,
          },
        },
      },
    },
  };
</script>
