<template>
  <div class="row">
    <div class="col s6">
      <div class="row">
        <h3
          v-if="title"
          class="is-size-3 is-inline-block"
        >
          {{ title }}
        </h3>
        <div class="col s1 is-inline-block is-header-button">
          <BButton
            v-if="!editing"
            icon-left="pencil"
            size="is-medium"
            type="is-white"
            @click="startEdit"
          />
        </div>
      </div>
      <slot
        :editing="editing"
        :data="innerdata"
      />
      <div
        v-if="editing"
        class="buttons"
      >
        <BButton
          v-if="savable"
          key="button-save"
          type="is-success"
          icon-left="content-save-outline"
          :loading="isSaving"
          :disabled="!childrenValid()"
          @click="saveEdit"
        >
          Opslaan
        </BButton>
        <BButton
          v-if="savable"
          key="button-cancel"
          icon-left="cancel"
          type="is-warning"
          @click="cancelEdit"
        >
          Annuleren
        </BButton>
      </div>
    </div>
  </div>
</template>

<script>
  import {BButton} from 'buefy/src/components/button';

  import {computed, ref} from 'vue';
  import usePostRequest from '@/js/vue/util/postRequest';

  export default {
    name: 'ViewAndEditWrapper',
    components: {
      BButton,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      disableSave: {
        type: Boolean,
        default: false,
      },
      postEndpoint: {
        type: String,
        default: '/api/wijzigen/user',
      },
    },
    setup(props) {
      const handlePostResponse = ref();
      const {
        isSaving,
        postRequest,
        postRequestErrorTitle,
      } = usePostRequest(computed(() => props.postEndpoint), handlePostResponse);
      postRequestErrorTitle.value = 'Fout opgetreden bij het bijwerken van je gegevens';

      return {
        isSaving,
        handlePostResponse,
        postRequest,
      };
    },
    data() {
      return {
        editing: false,
        innerdata: {},
        validatedChildren: [],
      };
    },
    computed: {
      savable() {
        return !this.disableSave;
      },
    },
    watch: {
      data: {
        immediate: true, // Voer handler al uit bij initialisatie component
        handler(value) {
          if (!this.editing) {
            if (this.savable) {
              this.getData();
            } else {
              this.innerdata = value; // copy by reference
            }
          }
        },
      },
    },
    mounted() {
      this.handlePostResponse = this.onPostRequestSucces; // deduplicate this on full setup migration
      for (const child of this.$children) {
        if (child.$v) {
          this.validatedChildren.push(child);
        }
      }
    },
    methods: {
      getData() {
        this.innerdata = structuredClone(this.data);
      },
      setData() {
        this.data = structuredClone(this.innerdata);
      },
      startEdit() {
        this.editing = true;
      },
      cancelEdit() {
        this.getData(); // Herstel alle oude data
        this.editing = false;
      },
      childrenValid() {
        // Hervalideer eerst alle kind-elementen
        for (const child of this.validatedChildren) {
          child.$v.$touch();
        }
        // Kijk vervolgens of er validatiefouten gevonden zijn
        for (const child of this.validatedChildren) {
          if (child.$v.$invalid) {
            return false;
          }
        }
        return true;
      },
      saveEdit() {
        if (!this.childrenValid()) {
          return;
        }
        this.postRequest(this.innerdata);
      },
      onPostRequestSucces() {
        this.setData();
        this.editing = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .is-header-button {
    line-height: 1em;
    vertical-align: bottom;
    margin-left: 0;
  }
</style>
