import {computed} from 'vue';

import {
  CursusStatus,
  type Cursus,
  type CursusVak,
  type Vak,
  type VakkenOverzicht,
  type VakNiveau,
} from '@/js/types/models/vakkenoverzicht';

export const useVakkenoverzichtProps = () => ({
  niveau: {
    type: String,
    default: undefined,
  },
  vakkenOverzicht: {
    type: Object,
    required: true,
  },
});

export interface VakkenoverzichtProps {
  niveau: VakNiveau;
  vakkenOverzicht: VakkenOverzicht;
}

const cursusIsOpen = (cursus: Cursus) => cursus.status !== CursusStatus.Gesloten;

export const getVakGegevens = (cursusVak: CursusVak): Vak => ({
  Vak_ID: cursusVak.Vak_ID,
  niveau: cursusVak.niveau,
  code: cursusVak.code,
  naam: cursusVak.naam,
});

export default function useVakkenoverzicht<Props extends VakkenoverzichtProps>(props: Props) {
  const cursussenPerVak = computed(() => props.vakkenOverzicht.cursussen?.[props.niveau] ?? []);
  const cursussen = computed<Array<Cursus & Vak>>(() => cursussenPerVak.value.map((cursusVak) =>
    Object.values(cursusVak.periodes).map((cursus) => ({
      ...cursus,
      ...getVakGegevens(cursusVak),
    }))).flat(1));
  const cursussenPerPeriode = computed(() => Object.groupBy(cursussen.value, ({Periode_ID: id}) => id));

  const periodes = computed(() => props.vakkenOverzicht.periodes ?? []);
  const openPeriodes = computed(() => periodes.value.filter((periode) =>
    cursussenPerPeriode.value?.[periode.Periode_ID]?.some((cursus) => cursusIsOpen(cursus)) ?? false));

  const getCursussenForVak = (vak: Pick<Vak, 'Vak_ID'>) =>
    Object.values(cursussenPerVak.value.find((cursusVak) => cursusVak.Vak_ID === vak.Vak_ID)?.periodes ?? {});
  const getOpenCursussenForVak = (vak: Pick<Vak, 'Vak_ID'>) =>
    getCursussenForVak(vak).filter((cursus) => cursusIsOpen(cursus));

  return {
    cursussen,
    cursussenPerPeriode,
    cursussenPerVak,
    periodes,
    openPeriodes,
    getCursussenForVak,
    getOpenCursussenForVak,
  };
}
