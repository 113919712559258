<template>
  <div
    id="betalingen"
    class="betalingen-overzicht"
  >
    <h2 class="is-size-2 is-inline-block">
      Betalingen
      <div class="col s1 is-inline-block is-header-button">
        <BButton
          v-if="vernieuwbaar"
          size="is-large"
          type="is-white"
          icon-left="sync"
          @click="refreshBetalingen"
        />
      </div>
    </h2>
    <LazyLoadingWrapper
      :loading="isLoading"
      :has-error="hasError"
    >
      <div class="table-container">
        <table class="table minwidth-70">
          <tr
            v-for="(cursus, key) in cursusoverzicht"
            :key="key"
          >
            <td>
              Cursus {{ cursus.Naam + " " + cursus.Niveau.toLowerCase() }}
              ({{ formatDagen(cursus.AantalDagen) }}){{ cursus.Bevestigd ? ' - bevestigd' : '' }}:
            </td>
            <td>
              {{ formatAmountInCents(cursus.PrijsCursus) }}
            </td>
          </tr>
          <tr v-if="betalingsoverzicht.Korting !== 0">
            <td>Korting:</td><td>&minus; {{ formatAmountInCents(betalingsoverzicht.Korting) }}</td>
          </tr>
          <tr class="tabel-totaal">
            <td>Totaal:</td> <td>{{ formatAmountInCents(betalingsoverzicht.Totaal) }}</td>
          </tr>
          <tr v-if="betalingsoverzicht.BetaaldDoorSchool > 0">
            <td>Vergoed door school:</td> <td>{{ formatAmountInCents(betalingsoverzicht.BetaaldDoorSchool) }}</td>
          </tr>
          <tr v-if="betalingsoverzicht.BetaaldDoorLeerling > 0 || betalingsoverzicht.BetaaldDoorSchool === 0">
            <td>Reeds voldaan:</td> <td>{{ formatAmountInCents(betalingsoverzicht.BetaaldDoorLeerling) }}</td>
          </tr>
          <tr class="tabel-tebetalen">
            <td><strong>Nog te voldoen:</strong></td>
            <td>
              <strong>{{ formatAmountInCents(betalingsoverzicht.NogTeBetalen) }}</strong>
              <SslInfoPopover
                v-if="betalingsoverzicht.NogTeBetalen < 0"
                key="info-restituties"
                title="Restituties"
              >
                Het teveel betaalde cursusbedrag wordt binnen een maand teruggestort.
              </SslInfoPopover>
              <SslInfoPopover
                v-if="betalingsoverzicht.NogTeBetalen > 0"
                key="info-betalen"
                title="Betalen"
              >
                Je kunt het totaal openstaande bedrag nu al voldoen via iDeal. Opmerkingen:
                <ul>
                  <li>
                    Het totale bedrag kan veranderen: bijvoorbeeld als een tweedaagse cursus wordt omgezet in een
                    driedaagse of andersom of wanneer je een vak toevoegt of verwijdert.
                  </li>
                  <li>
                    Je mag dus het cursusbedrag al voldoen maar dit hoeft nu nog niet.
                    Je ontvangt hierover van ons nog een herinnering ongeveer een maand voordat je cursus aanvangt.
                  </li>
                </ul>
              </SslInfoPopover>
            </td>
          </tr>
          <tr
            v-if="betalingsoverzicht.NogTeBetalenBevestigd > 0"
          >
            <td><strong>Nog te voldoen (bevestigde cursussen):</strong></td>
            <td>
              <strong>{{ formatAmountInCents(betalingsoverzicht.NogTeBetalenBevestigd) }}</strong>
            </td>
          </tr>
        </table>
      </div>
      <div class="buttons">
        <a
          v-if="betalingsoverzicht.NogTeBetalen > 0"
          class="button is-success"
          :href="betaalUrl"
        >
          <img
            :src="idealLogoUrl"
            class="ideal-logo"
            loading="lazy"
          />
          Betalen totaalbedrag
        </a>
        <a
          v-if="betalingsoverzicht.NogTeBetalenBevestigd > 0"
          class="button is-success"
          :href="betaalUrl + '?bevestigd=1'"
        >
          <img
            :src="idealLogoUrl"
            class="ideal-logo"
            loading="lazy"
          />
          Betalen bevestigde cursussen
        </a>
      </div>
    </LazyLoadingWrapper>
  </div>
</template>

<script setup lang="ts">
  import {BButton} from 'buefy/src/components/button';
  import {computed, watch, getCurrentInstance} from 'vue';

  import {formatAmountInCents} from '../../util/locale';

  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';

  import useGetRequest from '../util/getRequest';

  import LazyLoadingWrapper from '../wrappers/LazyLoadingWrapper.ts.vue';

  import idealLogoUrl from '@/images/svg/ideal.svg?url';

  interface BetalingsOverzicht {
    Leerling_ID: number;
    CursusBedrag: `${number}`;
    CursusBedragBevestigd: `${number}`;
    Korting: number;
    Totaal: `${number}`;
    TotaalBevestigd: `${number}`;
    BetaaldDoorSchool: `${number}`;
    AlBetaald: `${number}`;
    NogTeBetalen: `${number}`;
    NogTeBetalenBevestigd: `${number}`;
  }
  // Define the formatted betalings overzicht type to include BetaaldDoorLeerling
  type FormattedBetalingsOverzicht = {
    [K in keyof BetalingsOverzicht]: BetalingsOverzicht[K] extends `${number}` ? number : BetalingsOverzicht[K];
  } & {
    BetaaldDoorLeerling: number;
  };

  interface CursusOverzichtItem {
    Leerling_ID: number;
    Niveau: string;
    Naam: string;
    AantalDagen: number;
    Bevestigd: 0 | 1;
    PrijsCursus: number;
  }

  type CursusOverzicht = Array<CursusOverzichtItem>;

  interface BetalingenOverzichtResponse {
    betalingsoverzicht: BetalingsOverzicht;
    cursusoverzicht: CursusOverzicht;
    vernieuwbaar: boolean;
  }

  interface Props {
    inschrijvingen?: Array<unknown>;
    overzichtUrl: string;
    betaalUrl: string;
    refreshUrl: string;
  }
  const props = withDefaults(
    defineProps<Props>(),
    {
      inschrijvingen: () => [],
    },
  );

  const formatDagen = (aantal: number) => (aantal === 1) ? '1 dag' : `${aantal} dagen`;

  const {
    result: betalingenData,
    reload: refreshOverzicht,
    isLoading,
    hasError,
  } = useGetRequest<BetalingenOverzichtResponse>(
    computed(() => props.overzichtUrl),
    {betalingsoverzicht: {} as BetalingsOverzicht, cursusoverzicht: [], vernieuwbaar: false},
  );

  const formatBetalingsoverzicht = (overzicht: BetalingsOverzicht): FormattedBetalingsOverzicht => ({
    ...overzicht,
    CursusBedrag: Number(overzicht.CursusBedrag),
    CursusBedragBevestigd: Number(overzicht.CursusBedragBevestigd),
    Totaal: Number(overzicht.Totaal),
    TotaalBevestigd: Number(overzicht.TotaalBevestigd),
    BetaaldDoorSchool: Number(overzicht.BetaaldDoorSchool),
    BetaaldDoorLeerling: Number(overzicht.AlBetaald) - Number(overzicht.BetaaldDoorSchool),
    AlBetaald: Number(overzicht.AlBetaald),
    NogTeBetalen: Number(overzicht.NogTeBetalen),
    NogTeBetalenBevestigd: Number(overzicht.NogTeBetalenBevestigd),
  });

  const betalingsoverzicht = computed(() => formatBetalingsoverzicht(betalingenData.value.betalingsoverzicht));
  const cursusoverzicht = computed(() => betalingenData.value.cursusoverzicht);
  const vernieuwbaar = computed(() => betalingenData.value.vernieuwbaar);

  const app = getCurrentInstance();
  const $buefy = app?.proxy?.$root?.$buefy;

  const refreshBetalingen = async() => {
    $http.get(props.refreshUrl)
      .then((response) => {
        if (response.status === 200) {
          refreshOverzicht();
        }
      });
    $buefy?.dialog.alert('Het betalingsoverzicht wordt vernieuwd. Dat kan ca. 1 minuut duren.');
  };

  watch(
    () => props.inschrijvingen,
    refreshOverzicht,
    {deep: true},
  );
</script>

<style lang="scss" scoped>
  .table.minwidth-70 {
    width: auto;
    min-width: 70%;
  }

  .is-header-button {
    line-height: 1em;
    vertical-align: bottom;
    margin-left: 0;
  }

  .ideal-logo {
    width: 48px;
    height: 48px;
    border: 0;
    padding: 0 1em 0 0;
  }
</style>
