<template>
  <div class="inschrijvingen-overzicht">
    <h2 class="is-size-2">
      Indeling vakken
    </h2>
    <LazyLoadingWrapper
      :loading="isLoading"
      :has-error="loadingError"
    >
      <template v-if="`Bevestigd` in inschrijvingen">
        <span class="subtitle">
          Bevestigde periodes
        </span>
        <p class="info">
          Voor de volgende vakken is je indeling <strong>bevestigd</strong>.
          Mocht je toch iets willen wijzigen, stuur ons gerust een
          <a href="mailto:info@sslleiden.nl">mailtje</a> met je wens.
        </p>
        <BTable
          class="minwidth-70"
          :data="inschrijvingen.Bevestigd"
          hoverable
          mobile-cards
        >
          <BTableColumn
            v-slot="props"
            field="inschrijving"
            label="Periode"
          >
            <strong>
              {{ props.row.periode.Omschrijving }}
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="afwijkendeDagen(props.row.periode.DagenAfwijkend)" />
            </strong>
          </BTableColumn>
          <BTableColumn
            v-slot="props"
            field="vak"
            label="Vak"
          >
            {{ props.row.vak.Naam }}
          </BTableColumn>
        </BTable>
      </template>
      <template v-if="`Vastgelegd` in inschrijvingen">
        <span class="subtitle">
          Vastgelegde periodes
        </span>
        <p class="info">
          Op jouw verzoek hebben we de indeling voor de volgende vakken <strong>vastgelegd</strong>.
          Deze indeling is nog niet definitief, de volgorde van de vakken kan nog wel wijzigen.
          Mocht je toch iets willen wijzigen, stuur ons gerust een
          <a href="mailto:info@sslleiden.nl">mailtje</a> met je wens.
        </p>
        <BTable
          class="minwidth-70"
          :data="inschrijvingen.Vastgelegd"
          hoverable
          mobile-cards
        >
          <BTableColumn
            v-slot="props"
            field="inschrijving"
            label="Periode"
          >
            <strong>
              {{ props.row.periode.Omschrijving }}
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="afwijkendeDagen(props.row.periode.DagenAfwijkend)" />
            </strong>
          </BTableColumn>
          <BTableColumn
            v-slot="props"
            field="vak"
            label="Vak"
          >
            {{ props.row.vak.Naam }}
          </BTableColumn>
        </BTable>
      </template>
      <template v-if="`Ingeschreven` in inschrijvingen && inschrijvingen.Ingeschreven.length > 0">
        <span class="subtitle">
          Inschrijvingen
        </span>
        <p class="info">
          Je bent ingeschreven voor de volgende vakken.
          Uiterlijk <strong>tien dagen voor de cursus</strong> ontvang je een mail met je definitieve indeling.
          Vanzelfsprekend proberen we daarbij rekening te houden met je voorkeuren (als je die hebt opgegeven),
          maar kunnen deze niet garanderen.
        </p>
        <BTable
          class="minwidth-70"
          :data="inschrijvingen.Ingeschreven"
          hoverable
          mobile-cards
        >
          <BTableColumn
            v-slot="props"
            field="vak"
            label="Vak"
          >
            <strong>{{ props.row.vak.Naam }}</strong>
          </BTableColumn>
          <BTableColumn
            v-slot="props"
            field="indeling"
            label="Inschrijving"
          >
            <span v-if="props.row.vak.Wachtlijst">
              Voorlopig ingedeeld in {{ props.row.periode.Omschrijving }}<br />
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="afwijkendeDagen(props.row.periode.DagenAfwijkend)" />

              <em>Wachtlijst in {{ props.row.vak.Voorkeursperiode }}</em>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="afwijkendeDagen(props.row.vak.VoorkeurDagenAfwijkend)" />

              <SslInfoPopover title="Wachtlijst">
                Voor dit vak sta je op een wachtlijst voor je voorkeursperiode.
                Er is op dit moment dus helaas geen plek voor je beschikbaar in
                {{ props.row.vak.VoorkeursperiodeNaam }}.
                Om je toch een cursusplek te kunnen aanbieden, ben je voorlopig ingedeeld in
                {{ props.row.periode.Naam }}.
                Mocht er toch een plek vrijkomen, dan nemen we contact met je op.
              </SslInfoPopover>
            </span>
            <span v-else>
              <template v-if="cursusreeks.aantalPeriodes === 1">
                {{ props.row.periode.Omschrijving }}
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="afwijkendeDagen(props.row.periode.DagenAfwijkend)" />
              </template>
              <template v-else>
                {{ props.row.vak.Voorkeursperiode
                  ? 'Voorkeursperiode: ' + props.row.vak.Voorkeursperiode
                  : 'Geen voorkeursperiode'
                }}
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="afwijkendeDagen(props.row.vak.VoorkeurDagenAfwijkend)" />
              </template>
            </span>
          </BTableColumn>
          <BTableColumn
            v-if="aantalInschrijvingen > 1"
            v-slot="props"
          >
            <BButton
              type="is-white"
              icon-right="delete"
              @click="() => confirmDelete(deleteInschrijving, props.row.vak, props.row.periode.Periode_ID)"
            />
          </BTableColumn>
        </BTable>
      </template>
      <template v-if="`Wachtlijst` in inschrijvingen && inschrijvingen.Wachtlijst.length > 0">
        <span class="subtitle">
          Wachtlijsten
        </span>
        <p class="info">
          Voor de volgende vakken sta je op een wachtlijst.
          Er is op dit moment dus helaas geen cursusplek voor je beschikbaar.
          <template v-if="cursusreeks.aantalPeriodes > 1">
            Wil je zeker zijn van een plek, pas dan je voorkeur aan.
            Bekijk ons <a
              :href="vakkenoverzichtUrl()"
              target="_blank"
            >vakkenoverzicht</a> voor een volledig overzicht van alle beschikbare plekken.
          </template>
          Raadpleeg onze <a
            href="https://www.sslleiden.nl/FAQ#indelingwachtlijst"
            target="_blank"
          >FAQ</a> wanneer je vragen hebt over de wachtlijst.
        </p>
        <BTable
          class="minwidth-70"
          :data="inschrijvingen.Wachtlijst"
          hoverable
          mobile-cards
        >
          <BTableColumn
            v-slot="props"
            field="vak"
            label="Vak"
          >
            <strong>{{ props.row.vak.Naam }}</strong>
          </BTableColumn>
          <BTableColumn
            v-slot="props"
            field="indeling"
            label="Wachtlijst"
          >
            Wachtlijst voor {{ props.row.periode.Omschrijving }}
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="afwijkendeDagen(props.row.periode.DagenAfwijkend)" />
          </BTableColumn>
          <BTableColumn
            v-if="aantalInschrijvingen > 1"
            v-slot="props"
          >
            <BButton
              type="is-white"
              icon-right="delete"
              @click="() => confirmDelete(deleteWachtlijst, props.row.vak, props.row.periode.Periode_ID)"
            />
          </BTableColumn>
        </BTable>
      </template>
      <template v-if="`NietIngedeeld` in inschrijvingen">
        <span class="subtitle">
          Niet ingedeeld
          <SslInfoPopover title="Niet ingedeeld">
            Voor de volgende vakken hebben we je tot op heden niet kunnen indelen.
            Er is op dit moment dus helaas geen cursusplek voor je beschikbaar.
            <template v-if="cursusreeks.aantalPeriodes > 1">
              Verhoog je beschikbaarheid en/of geef een (andere) voorkeursperiode aan om zeker te zijn van een plaats.
              Bekijk ons <a
                :href="vakkenoverzichtUrl()"
                target="_blank"
              >vakkenoverzicht</a> voor een volledig overzicht van alle beschikbare plekken.
            </template>
          </SslInfoPopover>
        </span>
        <BTable
          class="minwidth-70"
          :data="inschrijvingen.NietIngedeeld"
          hoverable
          mobile-cards
        >
          <BTableColumn
            v-slot="props"
            field="vak"
            label="Vak"
          >
            <strong>{{ props.row.vak.Naam }}</strong>
          </BTableColumn>
          <BTableColumn
            v-slot="props"
            field="indeling"
            label="Voorkeursperiode"
          >
            {{ props.row.vak.Voorkeursperiode
              ? 'Voorkeursperiode: ' + props.row.vak.Voorkeursperiode
              : 'Geen voorkeursperiode'
            }}
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="afwijkendeDagen(props.row.vak.VoorkeurDagenAfwijkend)" />
          </BTableColumn>
        </BTable>
      </template>
    </LazyLoadingWrapper>
    <div class="buttons">
      <template v-if="cursusreeks.aantalPeriodes > 1">
        <BButton
          type="is-success"
          icon-left="plus-circle"
          tag="a"
          :href="vakkenToevoegenUrl"
        >
          Vakken toevoegen
        </BButton>
        <BButton
          type="is-success"
          icon-left="calendar-check"
          tag="a"
          :href="voorkeurenAanpassenUrl"
        >
          Voorkeuren aanpassen
        </BButton>
      </template>
      <template v-else-if="!(`Bevestigd` in inschrijvingen)">
        <BButton
          type="is-success"
          icon-left="arrow-left-right-bold"
          @click="vakVeranderen"
        >
          Vak veranderen
        </BButton>
      </template>
      <BButton
        type="is-success"
        icon-left="format-list-numbered"
        @click="cijfersAanpassen"
      >
        Cijfers aanpassen
      </BButton>
    </div>
  </div>
</template>

<script>
  import {BButton} from 'buefy/src/components/button';
  import {BTable, BTableColumn} from 'buefy/src/components/table';
  import {computed, del as deleteReactive, ref, set as setReactive} from 'vue';

  import SslCijferEditor from '../elements/SslCijferEditor.vue';
  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import SslVakKiezer from '../elements/SslVakKiezer.vue';

  import sslDateFormatter from '../mixins/SslDateFormatter';
  import LazyLoadingWrapper from '../wrappers/LazyLoadingWrapper.ts.vue';

  import usePostRequest from '@/js/vue/util/postRequest';

  const inschrijvingsTypen = ['Bevestigd', 'Vastgelegd', 'Ingeschreven', 'Wachtlijst', 'NietIngedeeld'];

  export default {
    name: 'InschrijvingenOverzicht',
    components: {
      BButton,
      BTable,
      BTableColumn,
      LazyLoadingWrapper,
      SslInfoPopover,
    },
    mixins: [
      sslDateFormatter,
    ],
    props: {
      cursusreeks: {
        type: Object,
        required: true,
      },
      inschrijvingen: {
        type: Object,
        required: true,
      },
      inschrijvingenEndpoint: {
        type: String,
        required: true,
      },
      vakkenToevoegenUrl: {
        type: String,
        required: true,
      },
      voorkeurenAanpassenUrl: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const postEndpoint = computed(() => `/api/wijzigen/verwijder_vak/${props.cursusreeks.slug}`);
      const handlePostResponse = ref();
      const {
        postRequest,
        postRequestErrorTitle,
      } = usePostRequest(postEndpoint, handlePostResponse);

      postRequestErrorTitle.value = 'Fout opgetreden bij het verwijderen van je inschrijving';

      return {
        handlePostResponse,
        postRequest,
      };
    },
    data() {
      return {
        deleted: {},
        isLoading: true,
        loadingError: false,
      };
    },
    computed: {
      aantalInschrijvingen() {
        let res = 0;
        inschrijvingsTypen.forEach((type) => {
          if (type in this.inschrijvingen) {
            res = res + this.inschrijvingen[type].length;
          }
        });
        return res;
      },
    },
    mounted() {
      this.handlePostResponse = this.onDeleteRequestSucces; // deduplicate this on full setup migration
      this.refreshInschrijvingen();
      const url = new URL(window.location.href);
      if (url.searchParams.get('actie') === 'cijfers_aanpassen') {
        this.cijfersAanpassen();
      }
    },
    methods: {
      afwijkendeDagen(dagen) {
        return (dagen.length > 0)
          ? '<br />'
            + '<span class="icon has-text-warning mdi mdi-alert alert"></span>'
            + `<em>Let op! Dit vak wordt in deze periode alleen aangeboden op ${this.omschrijfDagen(dagen)}.</em>`
          : '';
      },
      omschrijfDagen(dagen) {
        const L = dagen.length;
        if (L === 1) {
          return this.dagNaarDagString(dagen[0]);
        } else if (L === 2) {
          return this.dagNaarDagString(dagen[0], 'dd D MMM') + ' en ' + this.dagNaarDagString(dagen[1]);
        }
        // 1 dag = 86400000 ms
        let prevDagTime = new Date(dagen[0]).getTime() - 86400000;
        const consecutive = dagen.every((dag) => {
          const dagTime = new Date(dag).getTime();
          const res = (dagTime - prevDagTime === 86400000);
          prevDagTime = new Date(dag).getTime();
          return res;
        });
        if (consecutive) {
          return this.dagNaarDagString(dagen[0], 'dd D MMM') + ' t/m ' + this.dagNaarDagString(dagen[L - 1]);
        }
        return dagen.reduce((res, dag, i) => {
          const sep = (i === 0) ? '' : ((i === L - 1) ? ' en ' : ', ');
          const year = (i === L - 1) ? this.dagNaarDagString(dag, ' YYYY') : '';
          return res + sep + this.dagNaarDagString(dag, 'dd D MMM') + year;
        }, '');
      },
      refreshInschrijvingen() {
        this.isLoading = true;
        this.loadingError = false;
        $http.get(this.inschrijvingenEndpoint).then((response) => {
          inschrijvingsTypen.forEach((type) => {
            if (type in response.data) {
              setReactive(this.inschrijvingen, type, response.data[type]);
            } else {
              deleteReactive(this.inschrijvingen, type);
            }
          });
        }).catch(() => {
          this.loadingError = true;
        }).finally(() => {
          this.isLoading = false;
        });
      },
      vakkenoverzichtUrl() {
        return 'https://www.sslleiden.nl/' + this.cursusreeks.Trainingstype + '/vakkenoverzicht';
      },
      deleteInschrijving(vakID, periodeID) {
        this.deleted = {
          source: 'Ingeschreven',
          vakID: vakID,
          periodeID: periodeID,
        };
        this.deleteVak(vakID, periodeID);
      },
      deleteWachtlijst(vakID, periodeID) {
        this.deleted = {
          source: 'Wachtlijst',
          vakID: vakID,
          periodeID: periodeID,
        };
        this.deleteVak(vakID, periodeID);
      },
      deleteVak(vakID, periodeID) {
        this.postRequest({
          Vak_ID: vakID,
          Periode_ID: periodeID,
        });
      },
      onDeleteRequestSucces() {
        const index = this.inschrijvingen[this.deleted.source].findIndex(
          (indeling) => (
            indeling.vak.Vak_ID === this.deleted.vakID
            && indeling.periode.Periode_ID === this.deleted.periodeID
          ),
        );
        deleteReactive(this.inschrijvingen[this.deleted.source], index);
        this.deleted = {};
      },
      confirmDelete(callback, vak, periodeID) {
        return this.$buefy.dialog.confirm({
          title: 'Inschrijving annuleren',
          message: 'Weet je zeker dat je je inschrijving voor ' + vak.Naam + ' wilt annuleren?',
          type: 'is-danger',
          hasIcon: true,
          confirmText: 'OK',
          cancelText: 'Annuleren',
          onConfirm: () => callback(vak.Vak_ID, periodeID),
        });
      },
      cijfersAanpassen() {
        this.$buefy.modal.open({
          parent: this,
          trapFocus: true,
          component: SslCijferEditor,
          hasModalCard: true,
          customClass: 'ssl-modal',
          props: {
            cursusreeks: this.cursusreeks,
          },
          events: {
            save: this.cijfersOpgeslagen,
          },
        });
      },
      cijfersOpgeslagen() {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Cijfers succesvol opgeslagen!',
          type: 'is-success',
        });
      },
      vakVeranderen() {
        this.$buefy.modal.open({
          parent: this,
          trapFocus: true,
          component: SslVakKiezer,
          hasModalCard: true,
          customClass: 'ssl-vak-kiezer-modal',
          props: {
            cursusreeks: this.cursusreeks,
          },
          events: {
            save: this.vakVeranderd,
          },
        });
      },
      vakVeranderd() {
        this.refreshInschrijvingen();
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Vak succesvol gewijzigd!',
          type: 'is-success',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.b-table.minwidth-70) {
    .table {
      width: auto;
      min-width: 70%;
    }
  }

  .icon-text {
    line-height: 1em;
    vertical-align: text-top; // Dit geeft de beste verticale alignment, kennelijk...
    padding-right: 0.2em;
  }

  .info {
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 5pt;

    strong {
      font-weight: 600;
    }
  }
</style>
