<template>
  <div>
    <h2 class="is-size-3">
      Voorkeuren
    </h2>
    <p>
      Je kunt hieronder per vak aangeven welke voorkeuren je hebt.
    </p>
    <p>
      We proberen rekening te houden met je voorkeursperiode, maar kunnen deze niet garanderen.
      Uiterlijk tien dagen voor aanvang van je cursus ontvang je de definitieve bevestiging.
    </p>

    <BCollapse
      :open="voorkeurOpen"
      aria-id="contentIdForA11y1"
      @open="onVoorkeurOpen"
      @close="onVoorkeurClose"
    >
      <template #trigger="props">
        <a class="button is-primary">
          <span>{{ props.open ? 'Voorkeuren verbergen' : 'Voorkeuren uitvouwen' }}</span>
          <span class="icon mdi" :class="`mdi-${props.open ? 'minus-circle' : 'plus-circle'}`" />
        </a>
      </template>
      <section
        v-for="vak in orderedVakken"
        :key="vak.Vak_ID"
      >
        <h3 class="is-size-4">
          {{ vak.naam }}
        </h3>
        <template v-if="voorkeurVergrendeld(vak)">
          <BMessage
            type="is-info"
            has-icon
          >
            Je indeling voor dit vak is vastgelegd of bevestigd.
            Je bent ingedeeld in periode {{ vak.indeling }}.
            Mocht je toch iets willen wijzigen, stuur ons gerust een
            <a href="mailto:info@sslleiden.nl">mailtje</a> met je wens.
          </BMessage>
        </template>
        <template v-else>
          <template v-if="vraagOmVoorkeurslengte">
            <SslRadio
              v-model="data.voorkeurslengte[vak.Vak_ID]"
              :options="lengthOptions"
            >
              <template #label>
                Gaat je voorkeur uit naar een tweedaagse of driedaagse training?
                <SslInfoPopover title="Verschil twee- en driedaagse trainingen">
                  Meer informatie over het verschil tussen een tweedaagse en driedaagse training vind je <a
                    href="https://www.sslleiden.nl/examentraining/verschil-2-daagse-en-3-daagse-cursus"
                    target="_blank"
                  >hier</a>.
                </SslInfoPopover>
              </template>
            </SslRadio>
          </template>
          <FieldVoorkeursperiode
            v-model="data.voorkeursperiode[vak.Vak_ID]"
            :vakken-overzicht="vakkenOverzicht"
            :niveau="niveau"
            :vakken="vakken"
            :beschikbaarheid="beschikbaarheid"
            :vak="vak"
            @input="(val) => voorkeurUpdate(val, vak)"
          />
          <BMessage
            v-if="voorkeursPeriodeDubbel(vak.Vak_ID)"
            key="warning-dubbele-voorkeur"
            type="is-warning"
            has-icon
            title="Waarschuwing"
          >
            Je hebt deze voorkeursperiode bij meerdere vakken opgegeven.
            Het is echter niet mogelijk om meerdere vakken in dezelfde periode te volgen.
          </BMessage>
          <BMessage
            v-if="voorkeurMismatch(vak.Vak_ID)"
            key="warning-lengte-voorkeur"
            type="is-warning"
            has-icon
            title="Waarschuwing"
          >
            Je hebt voorkeur voor een {{ lengthOptions[data.voorkeurslengte[vak.Vak_ID]].toLowerCase() }}e training,
            maar die wordt niet aangeboden in je voorkeursperiode.
          </BMessage>
          <SslTextField
            v-if="!vak.ingeschreven"
            v-model="data.opmerkingen[vak.Vak_ID]"
            label="Opmerkingen"
            placeholder="Ruimte voor overige opmerkingen over je voorkeur voor dit vak"
          />
        </template>
      </section>
    </BCollapse>
  </div>
</template>

<script>
  import {BCollapse} from 'buefy/src/components/collapse';
  import {BMessage} from 'buefy/src/components/message';
  import {del as deleteReactive, set as setReactive} from 'vue';

  import SslInfoPopover from '../elements/SslInfoPopover.ts.vue';
  import SslRadio from '../elements/SslRadio.vue';
  import SslTextField from '../elements/SslTextField.vue';
  import FieldVoorkeursperiode from '../fields/FieldVoorkeursperiode.vue';

  export default {
    name: 'PartialFormVoorkeur',
    components: {
      BCollapse,
      BMessage,
      SslTextField,
      SslRadio,
      SslInfoPopover,
      FieldVoorkeursperiode,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      vakkenOverzicht: {
        type: Object,
        required: true,
      },
      niveau: {
        type: String,
        required: true,
      },
      vakken: {
        type: Array,
        required: true,
      },
      beschikbaarheid: {
        type: Object,
        required: true,
      },
      indelingen: {
        type: Object,
        required: true,
      },
      vraagOmVoorkeurslengte: {
        type: Boolean,
        default: false,
      },
      wijzigingenOvernemen: {
        type: Boolean,
        default: true, // eslint-disable-line vue/no-boolean-default
      },
      standaardUitklappen: {
        type: Boolean,
        defaut: false,
      },
    },
    data() {
      return {
        lengthOptions: {tweeDaags: 'Tweedaags', drieDaags: 'Driedaags', geenVoorkeur: 'Geen voorkeur'},
        voorkeurOpen: this.standaardUitklappen,
      };
    },
    computed: {
      orderedVakken: function() {
        const result = [...this.vakken];
        return result.sort((a, b) => a.naam.localeCompare(b.naam));
      },
    },
    watch: {
      vakken: function(newVal) {
        for (const vak of newVal) {
          if (this.data.voorkeursperiode[vak.Vak_ID] === undefined) {
            setReactive(this.data.voorkeursperiode, vak.Vak_ID, null);
          }
          if (this.data.voorkeurslengte[vak.Vak_ID] === undefined) {
            setReactive(this.data.voorkeurslengte, vak.Vak_ID, 'geenVoorkeur');
          }
          if (this.data.opmerkingen[vak.Vak_ID] === undefined) {
            setReactive(this.data.opmerkingen, vak.Vak_ID, null);
          }
        }
      },
      beschikbaarheid: {
        handler: function(val) {
          // als je niet meer beschikbaar bent: dit kan niet meer je indeling of voorkeursperiode zijn
          Object.keys(this.indelingen).forEach((periode) => {
            if (val[periode] === '0') {
              deleteReactive(this.indelingen, periode);
            }
          });
          Object.keys(this.data.voorkeursperiode).forEach((vak) => {
            const periode = this.data.voorkeursperiode[vak];
            if (val[periode] === '0') {
              setReactive(this.data.voorkeursperiode, vak, null);
            }
          });
        },
        deep: true,
      },
    },
    methods: {
      voorkeurVergrendeld: function(vak) {
        return (vak.ingeschreven && !vak.aanpasbaar);
      },
      voorkeurMismatch: function(vakID) {
        const voorkeursperiode = Object.values(this.vakkenOverzicht.cursussen[this.niveau]
          .find((vak) => vak.Vak_ID === vakID).periodes)
          .find((periode) => periode.Periode_ID === this.data.voorkeursperiode[vakID]);
        return (voorkeursperiode !== undefined)
          && ((this.data.voorkeurslengte[vakID] === 'tweeDaags' && this.periodeLengte(voorkeursperiode) !== 2)
            || (this.data.voorkeurslengte[vakID] === 'drieDaags' && this.periodeLengte(voorkeursperiode) !== 3));
      },
      periodeLengte: function(periode) {
        return (periode.dagen.length - periode.nietopdagen.length);
      },
      voorkeurCount: function() {
        const voorkeursPeriodes = Object.values(this.data.voorkeursperiode);
        const res = {};
        voorkeursPeriodes.forEach((id) => {
          if (id in res) {
            res[id] = res[id] + 1;
          } else {
            res[id] = 1;
          }
        });
        return res;
      },
      voorkeursPeriodeDubbel: function(vakID) {
        const id = this.data.voorkeursperiode[vakID];
        return (id !== null && (this.voorkeurCount()[id] > 1));
      },
      indelingAanpassen: function(vak, target) {
        if (!vak.ingeschreven) {
          // voor nieuwe vakken: indeling mag altijd worden aangepast
          return true;
        }
        if (!this.wijzigingenOvernemen) {
          return false;
        }
        if (vak.wachtlijst) {
          return true;
        }

        // Als je niet op een wachlijst staat, alleen indeling aanpassen als je naar een open periode verplaatst of geen voorkeur opgeeft
        return (!target || vak.periodes[target].status === 'Open');
      },
      voorkeurUpdate: function(key, vak) {
        if (this.indelingAanpassen(vak, key)) {
          if (key && !(key in this.indelingen)) {
            // deel het vak in als het kan
            Object.keys(this.indelingen).forEach((periodeID) => {
              if (this.indelingen[periodeID] === vak.Vak_ID) {
                // verwijder eerst de 'oude' indeling van dit vak
                deleteReactive(this.indelingen, periodeID);
              }
            });
            setReactive(this.indelingen, key, vak.Vak_ID);
          } else if (!key) {
            Object.keys(this.indelingen).forEach((periodeID) => {
              if (this.indelingen[periodeID] === vak.Vak_ID) {
                // verwijder de eventuele indeling
                deleteReactive(this.indelingen, periodeID);
              }
            });
          }
        }
      },
      onVoorkeurOpen() {
        gtag('event', 'open_voorkeuren', {
          event_category: 'formulier',
        });
      },
      onVoorkeurClose() {
        gtag('event', 'sluit_voorkeuren', {
          event_category: 'formulier',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .periodenaam {
    margin-bottom: 0;
    line-height: 1;
  }

  ul.periode-dagen {
    list-style-type: none;
    margin: 0;
    padding-left: 1rem;

    > li {
      display: inline;
      font-size: 0.9rem;
      font-weight: 600;
      color: #666;
    }

    li + li {
      &::before {
        content: "|";
        margin: 0 0.25rem;
      }
    }
  }

  ul.periode-vakken {
    list-style: none;
    padding-left: 1rem;
    margin: 0 0 0.25rem;
    font-weight: bold;

    li { /* stylelint-disable-line  no-descending-specificity */
      display: inline;

      &::after {
        content: ", ";
      }

      &:last-child::after {
        content: "";
      }
    }
  }

  section {
    margin: 1rem 0;
  }
</style>
